import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import "./PlaylistVideoPage.scss";
import {RouteComponentProps, useParams} from "react-router-dom";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import { VideoPlaylistResponse, User, CollectionType, ApiPlaylist, EAPDescriptionType } from "../../utils/ApiTypes";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { Feature } from '../../components/Feature/Feature';
import { TalkToSomeone } from '../../components/TalkToSomeone/TalkToSomeone';
import { t } from 'i18next';
import ContentGrid from '../../components/ContentGrid/ContentGrid';
import ClampLines from '../../components/ClampLines/ClampLines';
import useQueryChecker from '../../components/CustomHooks/useQueryChecker';


type PlaylistPageParams = {
    id: string;
};

export const PlaylistVideoPage: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {

    const {isMobileWidth, handleGeneralError, currentUser } = useContext(ApplicationContext);
    const [playlist, setPlaylist] = useState<ApiPlaylist | null>(null);
    const [playlistDetails, setPlaylistDetails] = useState<VideoPlaylistResponse | null>(null);
    const isComponentVisible = useIsComponentVisible();
    const params = useParams<PlaylistPageParams>();
    const {isMatch, isCheckLoading} = useQueryChecker("isAsset", "1");

    useEffect(() => {
        if (!isComponentVisible || isCheckLoading) return;
        const playlistId = params.id;
        if(isMatch){
            getAssetContent(params.id);
        } else {
            getVideoContent(playlistId);
        }
        setPlaylistDetails(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, isCheckLoading]);

    const getVideoContent = async (playlistId: string) => {
        try {
            const jsonResponse = await JourneyApiClient.getPlaylist(playlistId);
            setPlaylistDetails(jsonResponse);
        } catch (ex) {
            handleGeneralError("Unable to fetch Playlist", ex);
        }
    }

    async function getAssetContent(assetId: string) {
        try {
            const asset = await JourneyApiClient.getAsset(assetId);
            if(asset.type === CollectionType.Playlist){
                setPlaylist(asset);
            } else {
                handleGeneralError("Could not get playlist content", new Error("Asset is not a playlist"));
            }
        } catch(error) {
            handleGeneralError("Could not get playlist content", error);
        }
    }

    return(
        <IonPage className={`playlist-video-page`}>

            <ResponsiveNavigationMenu title={playlistDetails?.title ?? ''} />   

            <IonContent className="playlist-video-content">
                {playlistDetails ? 
                    <PlaylistVideoPageContent 
                        playlistDetails={playlistDetails} 
                        isMobileWidth={isMobileWidth} 
                        currentUser={currentUser}
                        isComponentVisible={isComponentVisible} /> : 
                playlist ? 
                    <PlaylistVideoPageContent 
                        playlistDetails={playlist} 
                        isMobileWidth={isMobileWidth} 
                        currentUser={currentUser}
                        isComponentVisible={isComponentVisible} /> : 
                    <PleaseWait/>}
            </IonContent>
        </IonPage>
    )
};

export type PlaylistVideoPageContentProps = {
    playlistDetails: VideoPlaylistResponse | ApiPlaylist;
    isMobileWidth: boolean;
    currentUser: User | null;
    isComponentVisible: boolean;
}

export const PlaylistVideoPageContent: React.FC<PlaylistVideoPageContentProps> = (props: PlaylistVideoPageContentProps) => {
    const proactiveClinicalHelpSubtitle = props.playlistDetails.clinicalCareCategory === "worklife" ? t("Our Work Life specialists are available 24/7 to support your child care, elder care, legal, or financial needs. All support is free and confidential.") : 
    t("Our Master's Level Clinicians are available 24/7 to provide a listening ear and have a free, confidential conversation about your mental health. Your employer will never have access to anything you share in the app or with our counselors.")
    return(
        <div className="playlist-content-container">
            <div className='playlist-header'>
                <div className={"playlist-title-description-container"}>
                    <div className={`${props.isMobileWidth ? "header-4" : "page-title"} playlist-title`}>{props.playlistDetails.title}</div>
                    <ClampLines
                        showButton={true}
                        text={props.playlistDetails.description ?? ''}
                        lines={8}
                        ellipsis="..."
                        moreText={t("Expand") ?? ''}
                        lessText={t("Collapse") ?? ''}
                        className={`clamp-description body-large`}
                    />
                </div>
                {props.playlistDetails.clinicalCareCategory && props.currentUser ? 
                    <Feature feature="proactive-clinical-care">
                        <TalkToSomeone
                            className={"playlist-page-talk-to-someone"}
                            user={props.currentUser}
                            isMobileWidth={props.isMobileWidth}
                            showDescription={false}
                            showDescriptionInPopover={false}
                            isCondensed={false}
                            eapDescription={EAPDescriptionType.Default}
                            subtitle={proactiveClinicalHelpSubtitle}
                            isComponentVisible={props.isComponentVisible} />
                    </Feature> 
                    : (props.playlistDetails.thumbnailUrl && <img src={props.playlistDetails.thumbnailUrl} alt="Playlist thumbnail" />)}

            </div>
            <div className="playlist-content-grid-wrapper industry-targeting-grid-wrapper">
                <ContentGrid content={props.playlistDetails.items} showTitle={false} showDesription={false} playlistId={props.playlistDetails.id} /> 
            </div>
        </div>
    );
}
