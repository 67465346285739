export interface ApiCompany {
    id: number;
    name: string;
    code: string;
    logoUrl: string | null;
    appLogoUrl: Maybe<string>;
    departments?: Department[];
    locations?: Location[];
    tiers?: Tier[];
    invitationSubject: string;
    invitationEmailId: Maybe<number>;
    enableUserTrackingEvents: boolean;
    isSsoLogin: boolean;
    deletedAt?: Date;
    status: string;
    type: Maybe<string>;
    isFriendsAndFamily: boolean;
    isJourneyOrg: boolean;
    hasLivePrivateWorkshops: boolean;
    hasJourneyLive: boolean;
    requiresDepartment: boolean;
    requiresTier: boolean;
    requiresLocation: boolean;
    friendsAndFamilyCompanyCode: Maybe<string>;
    registrationPagePlaylistId: Maybe<number>;
    registrationPageMessage: Maybe<string>;
    registrationPageImageUrl: Maybe<string>;
    customWebUrlRoot: Maybe<string>;
    industryCssClass: string;
    countries: ApiCountry[];
    registrationMatchField: string;
    ssoProvider?: Maybe<string>;
}

export interface User {
    id: number;
    email: Maybe<string>;
    firstName: string;
    lastName: string;
    journeyAuthToken?: string;
    companyCode?: string;
    company: ApiCompany;
    preferredEmail: Maybe<string>;
    departmentId: Maybe<number>;
    tierId: Maybe<number>;
    locationId: Maybe<number>;
    isDeleted: boolean;
    deletedAt?: Date;
    companyId: number;
    hasMobileAppInstalled: boolean;
    hasAddedMindfulBreak: boolean;
    hasSeenMonthlyAssessment: boolean;
    hasZoomAppInstalled: boolean;
    hasTeamsAppInstalled: boolean;
    hasSharedRegistrationLink: boolean;
    career: Maybe<string>;
    interests: Maybe<string[]>;
    userRoles: string[];
    lastLoginDate: Maybe<Date>;
    profileImage: string;
    invitationMessage: Maybe<string>;
    calendarEmailService: Maybe<string>;
    language: string;
    country: string;
    state: Maybe<string>;
    locale: Maybe<ApiResourcesContentLanguage>;
    dateOfBirth: Maybe<Date>;
    employeeId?: Maybe<string>;
    phoneNumber?: Maybe<string>;
    employeeType?: Maybe<string>;
    gender?: Maybe<string>;
    hireDate?: Maybe<Date>;
}

export type ApiUser = User;

export interface ApiCountry {
    id: string;
    name: string;
    defaultLocale: Maybe<ApiResourcesContentLanguage>;
}

export class ApiMonthlyCheckinAnswer {
    stressLevel: number | null = null;
    manageStressLevel: number | null = null;
    feelingsFrequency: number | null = null;
    manageFeelingsLevel: number | null = null;
    feelingsFrequencyAffectWork: number | null = null;
    daysEmotionallyChallenging: number | null = null;
    priorities: string | null = null;
    otherPriority: string | null = null;
}

export class ApiMonthlyCheckinAnswerV101 {
    stressLevel: number | null = null;
    feelingsFrequency: number | null = null;
    manageStressLevel: number | null = null;
    feelingsFrequencyAffectWork: number | null = null;
    daysEmotionallyChallenging: number | null = null;
    priorities: string | null = null;
}

export class ApiDailyCheckinAnswer {
    feelingRating: number | null = null;
    priorities: string | null = null;
    otherPriority: string | null = null;
    createdAt?: Date;
}

export class ApiOnboardingAnswer {
    gender: string | null = null;
    age: number | null = null;
    underMedicalCareLast6Months: boolean | null = null;
    zipcode: string | null = null;
    yearsAtCompany: number | null = null;
    currentRole: string | null = null;
}

export interface Benefit {
    imageUrl: Maybe<string>;
    name: Maybe<string>;
    description: Maybe<string>;
    redirectUrl: Maybe<string>;
    isOnHomePage: boolean;
    isEAP: boolean;
    order: number;
}

export interface EAPSupportPhone {
    title: string;
    subtitle: string;
    description: string;
    phoneNumber: string;
    countryCode: Maybe<string>;
    flagName: Maybe<string>;
    order: Maybe<number>;
}

export interface ApiDailyJourney {
    id: number;
    theme: string | null;
    body: string;
    truncatedBody: string | null;
    muxVideo: VideoResponse | null;
    muxAudio: VideoResponse | null;
    videoDescription: string | null;
    videoDescriptionNewApp: string | null;
    videoCoverImageUrl: string | null;
    publicationDate: Date;
    videoId?: number | null;
    audioId?: number | null;
}

export interface GetCompanyBenefitsInfoResponse {
    company: ApiCompany;
    standardBenefits: Benefit[];
    companyBenefits: Benefit[];
    supportPhones: EAPSupportPhone[];
}

export interface AvailableContentTypes {
    hasDailyJourney: boolean;
    hasBenefits: boolean;
    hasWpoContent: boolean;
}

export interface ApiRecommendation {
    thumbnailUrl: string;
    url: string;
    title: string;
}

export interface PwwAttendance {
    id: string;
    companyId: number;
    date: Date;
    attendees: number;
    teacherName?: string;
    timestamp: Date;
}

export interface Department {
    id: number;
    name: string;
    companyId: number;
}

export interface Location {
    id: number;
    name: string;
    companyId: number;
}

export interface Tier {
    id: number;
    name: string;
    companyId: number;
}



export type ApiLiveStream = ApiContentBase & {
    id: number;
    status: 'active' | 'idle';
    playbackUrl: string;
    days: string[];
    recurringTimeOfDayUTC?: string;
    oneTimeScheduleDate: string | null;
    isScheduledForNow: boolean;
    teacher: Maybe<ApiTeacher>;
    type: 'livestream';
    thumbnailUrl: string;
}



export interface ApiVideoDetails {
    id: number;
    title: string;
    duration: number;
    description: string;
}



export interface ApiComment {
    id: number;
    user: ApiContentUser;
    comment: string,
    createdAt: Date,
}

export interface ApiContentUser {
    id: number;
    name: string;

}

export interface ApiTeacher {
    id: number;
    firstName: string;
    lastName: string;
    title: Maybe<string>;
    bio: Maybe<string>;
    videoThumbnailImageS3Path: Maybe<string>;
    bannerThumbnailImageS3Path: Maybe<string>;
    headshotThumbnailImageS3Path: Maybe<string>;
    items?: Array<ContentItem>;
}

export interface SsoDomain {
    id: number;
    companyId: number;
    domain: string;
}

export interface RealmDiscovery {
    realm: string;
}

export type FormName = 'monthly' | 'onboarding' | 'daily' | 'none';

export const RECOMMENDATIONS_VISIT = 'recommendations-visit';
export const RESOURCES_VISIT = 'resources-visit';
export const DAILY_JOURNEY_VISIT = 'daily-journey-visit';
export const CHECKIN_VISIT = 'checkin-visit';


export type VideoResponse = ApiVideo;

export type VideoPlaylistResponse = ApiPlaylist;

export type VideoCategoryResponse = {
    id: number;
    name: string;
    description: string;
    thumbnailUrl: string | null;
    shortDescription: string | null;
    items: Array<ContentItem>;
}

export type WatchVideoActivityRequest = {
    videoId: number;
    position: number;
    duration: number;
}


export type ApiResourcesContentLanguage = {
    id: string;
    name: string;
    nameLocalised: Maybe<string>;
    alpha2CodeISO: Maybe<string>;
    country: string;
    countryId: string;
    languageCode: string;
    abbreviation: string;
    localizedLanguageName: string;
    localizedCountryName: Maybe<string>;
    isFullySupported: boolean;
    divisions: ApiResourcesContentDivision[];
}

export type ApiResourcesContentDivision = ResourceContent & {
    localeAbbreviation: string;
}

export type ApiResourcesContentModule = ResourceContent & {
    divisionId: string;
}

export type ApiResourcesContentCategory = ResourceContent & {
    moduleId: string;
}

export type ApiResourcesContentArticle = {
    id: string;
    categoryId: string;
    moduleId: string;
    title: string;
    body: string;
    teaser: Maybe<string>;
    externalPublishedDate: Maybe<Date>;
}


export type ApiResourcesContentFeaturedArticle = {
    articleId: string;
    title: string;
    teaser: Maybe<string>;
    imageS3Path: string;
    localeAbbreviation: string;
}

export type ResourceContent = {
    id: string;
    nameEnglish: string;
    nameNative: string;
    imageS3Path: Maybe<string>;
}

export type ContentItem = ApiAsset;
export type SearchResultContentItem = ApiAsset;


export type DeleteSavedVideoRequest = {
    videoId: number;
}

export type DeleteSavedPlaylistRequest = {
    playlistId: number;
}

export type SaveVideoContentResponse = {

}

export type DeleteVideoContentResponse = {

}

export type RemoveSavedVideoContentRequest = {
    videoId?: number;
    playlistId?: number;
}

export type FetchSavedVideoContentResponse = ContentItem[];

export type FetchZoomAppContentResponse = ContentItem[];

export type SaveActivity = {
    type: 'visit' | 'daily-journey-read' | 'video-watch' | 'daily-practice-watch' | 'checkin' | 'audio-listen';
}

export type ApiMonthlyActivity = {
    id: number;
    intensity: number;
    timestamp: Date;
    userId: number;
}

export interface WPOContentFiltering {
    languageId?: number;
    moduleId?: number;
    categoryId?: number[];
    divisionId?: number;
}

export type EapAskQuestionForm = {
    preferredContactMethod: "phone" | "email",
    phoneNumber: Maybe<string>;
    areasOfConcern: string;
    question: Maybe<string>;
}

export type AcuityAvailableDate = {
    date: string;
}

export type AcuityAvailableSlots = {
    time: string;
    slotsAvailable: number;
}


export type AcuityClinicianAvailableSlots = {
    date: string;
    times: string[];
}

export interface ApiClinician {
    id: number;
    firstName: string;
    lastName: string;
    pronouns: Maybe<string>;
    title: Maybe<string>;
    degree: Maybe<string>;
    bio: Maybe<string>;
    thumbnailUrl: Maybe<string>;
    thumbnailBase64: Maybe<string>;
    slots: string[];
    calendarId: Maybe<number>;
}

export interface ApiAcuityAppointment {
    clinicianId: number;
    clinicianFirstName: string;
    clinicianLastName: string;
    clinicianPronouns: Maybe<string>;
    clinicianTitle: Maybe<string>;
    clinicianDegree: Maybe<string>;
    clinicianBio: Maybe<string>;
    clinicianThumbnailUrl: string;
    appointmentDatetime: string;
    appointmentLink: string;
    appointmentId: number;
    appointmentDurationInMinutes: number;
}

export interface ApiInvitation {
    inviter: User;
    invitee: User;
}

export interface ApiLandingPage {
    id: number;
    name: string;
    company: ApiCompany;
    video: VideoResponse;
    playlist: Maybe<VideoPlaylistResponse>;
    title: Maybe<string>;
    message: string;
    showRegistrationForm: boolean;
    hasEAP: boolean;
}

export interface ApiResource {
    id: number;
    name: string;
    imageS3Path: string;
    redirectUrl: string;
}

export interface FeatureConfig {
    features: { [key: string]: boolean }
}



type ApiDirectoryNodeBase = {
    id: string;
    name: string;
    mimeType: string;
    sizeInBytes: number | null;
    parentId: string;
    description: string;
    createdAt: string;
}

export type ApiFileNode = ApiDirectoryNodeBase & {
    isFolder: false;
    thumbnailLink: string | null;
}

export type ApiFolderNode = ApiDirectoryNodeBase & {
    isFolder: true;
    children: ApiDirectoryNode[];
    totalDescendantCount: number;
}

export type ApiDirectoryNode = ApiFileNode | ApiFolderNode;

export const GOOGLE_DRIVE_FOLDER_MIME_TYPE = 'application/vnd.google-apps.folder';
export const HR_HUB_SHARED_FOLDER_NAME = "shared";
export const HR_HUB_ADMIN = "HrHubAdmin";

export enum SavedEntityType {
    Media = 'media',
    Collection = 'collection',
}

//Same as model in shared/db/models/Asset.ts
export enum AssetType {
    Media = 'media',
    Playlist = 'playlist',
    Topic = 'topic',
    HomePageCollection = 'home-page-collection',
}

//Same as model in shared/db/models/Collection.ts
export enum CollectionType {
    Playlist = 'playlist',
    Topic = 'topic',
    HomePageCollection = 'home-page-collection',
}
export enum CollectionThumbnailStyle {
    Mono = 'monochromatic',
    Topic = 'topic',
    Gradient = 'gradient',
    Audio = 'audio',
    Generic = 'generic',
    Theme = 'theme',
}
export enum EAPDescriptionType {
    ChildCare = 'child-care',
    ElderCare = 'elder-care',
    LegalAndFinancial = 'legal-and-financial',
    ResourcesForManagers = 'resources-for-managers',
    Default = 'default'
}
//Same as model in shared/db/models/Media.ts
export enum MediaType {
    Video = 'video',
    Audio = 'audio',
    Article = 'article'
}
export enum MediaThumbnailStyle {
    Video = 'video',
    Audio = 'audio',
    Article = 'wpocontent',
    Generic = 'generic',
}

export type ApiAsset = ApiVideo | ApiArticle | ApiPlaylist | ApiTopic | ApiHomePageCollection | ApiLiveStream;

export type AlgoliaMetaData = {
    priority: number;
    objectID : string;
    extras?: {[key: string]: string};
}

export type ApiTopic = ApiCollection & {
    type: CollectionType.Topic;
    eapDescription: EAPDescriptionType;
    items: ApiPlaylist[];
}

/**
 * We are using this type to uniquely identify the ONE collection that corresponds to the homepage
 * carousels.
 */
export type ApiHomePageCollection = ApiCollection & {
    type: CollectionType.HomePageCollection;
}


export type ApiCollection = ApiContentBase & {
    id: number;
    type: CollectionType;
    thumbnailStyle: CollectionThumbnailStyle;
    items: ApiAsset[];
    shortDescription?: string;
}

export type ApiMedia = ApiContentBase & {
    type: MediaType;
    isPublic: boolean;
    thumbnailStyle: MediaThumbnailStyle;
}

export type ApiPlaylist = ApiCollection & {
    id: number;
    description: string;
    shortDescription: string | null;
    items: ApiAsset[];
    type: CollectionType.Playlist;
    teacher: ApiTeacher | null;
    thumbnailStyle: string | null;
    tileTitle: string | null;
    clinicalCareCategory: Maybe<string>;
}

export type ApiVideo = ApiMedia & {
    id: number;
    type: MediaType.Video | MediaType.Audio;
    thumbnailUrl: Maybe<string>;
    path: string;
    description: string;
    duration: number;
    thumbnailS3Path: Maybe<string>;
    playbackId: string;
    muxVideoId: string;
    muxPlaybackId: string;
    position: number;
    liveRecordingDate: Date;
    teacher: Maybe<ApiTeacher>;
    thumbnailStyle: Maybe<string>;
    clinicalCareCategory: Maybe<string>;
}

export type ApiArticle = ApiMedia & {
    type: MediaType.Article;
    order: number;
    legacyId: Maybe<number>;
    isFeaturedContent: boolean;
    categoryId: Maybe<string>;
    body: string;
    teaser: string;
}

//  This is basically anything that could appear as a tile
export type ApiContentBase = {
    id: number | string; //TODO: Once we completely migrate from wpo article table, chnage this to number
    thumbnailUrl: Maybe<string>;
    path: string;
    title: string;
    isSaved: boolean;
}

export type HomePageContentResponse = {
    homePageCollection: ApiHomePageCollection | null;
    videoCategories: VideoCategoryResponse[];
}