import { JourneyApiClient } from "../../utils/JourneyApiClient";
import {Link, useLocation} from "react-router-dom";
import React, { SyntheticEvent, useState, useContext } from "react";
import "./Tile.scss";
import { CollectionThumbnailStyle, SavedEntityType, VideoPlaylistResponse } from "../../utils/ApiTypes";
import AnalyticsService from "../../misc/AnalyticsService";
import {SolidColorStyle} from "./ContentStyles/SolidColor/SolidColorStyle";
import {SpecialSeries} from "./ContentStyles/SpecialSeries/SpecialSeries";
import {ThemeStyle} from "./ContentStyles/Theme/ThemeStyle";
import {AudioStyle} from "./ContentStyles/Audio/AudioStyle";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { useTranslation } from "react-i18next";
import { GenericTile } from "./GenericTile";

export type PlaylistProps = {
    playlist: VideoPlaylistResponse;
    showDescription?: boolean;
    showTitle?: boolean;
    className?: string;
    onSaveChange?: (saved: boolean) => void;
};

export function PlaylistTile(props: PlaylistProps) {
    const { playlist} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(playlist.isSaved);
    const { industryCssClass } = useContext(ApplicationContext);
    const location = useLocation();
    const { t } = useTranslation();

    async function addToList(e: SyntheticEvent) {
        e.preventDefault()
        if (loading) return;
        try {
            setLoading(true);
            await JourneyApiClient.saveContent(SavedEntityType.Collection, playlist.id);
            setIsSaved(true);
            if (props.onSaveChange) props.onSaveChange(true);
            AnalyticsService.trackUserAction("save_playlist", location.pathname, { video_id: playlist.id });
        } finally {
            setLoading(false);
        }
    }

    async function removeFromList(e: SyntheticEvent) {
        e.preventDefault();
        if (loading) return;
        try {
            setLoading(true);
            await JourneyApiClient.removeSavedContent(SavedEntityType.Collection, playlist.id);
            if (props.onSaveChange) props.onSaveChange(false);
            AnalyticsService.trackUserAction("remove_save_playlist", location.pathname, { video_id: playlist.id });
        } finally {
            setLoading(false);
        }
    }
    function renderTile(theme: string | null) {
        switch (theme){
            case CollectionThumbnailStyle.Generic:
                return(
                    <GenericTile title={playlist.title} path={playlist.path} />
                )
            case CollectionThumbnailStyle.Mono:
                return(
                    <SolidColorStyle industryClass={industryCssClass} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} id={playlist.id} thumbnailUrl={playlist.teacher?.videoThumbnailImageS3Path ?? ""} tileTitle={t("teacher")} contentTitle={playlist.title}/>
                )
            case "teacher":
                //TODO: Remove this after algolia syncs
                return(
                    <SolidColorStyle industryClass={industryCssClass} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} id={playlist.id} thumbnailUrl={playlist.teacher?.videoThumbnailImageS3Path ?? ""} tileTitle={t("teacher")} contentTitle={playlist.title}/>
                )
            case CollectionThumbnailStyle.Theme:
                return(
                    <ThemeStyle industryClass={industryCssClass} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} playlist={playlist}/>
                )
            case CollectionThumbnailStyle.Gradient:
                return(
                    <SpecialSeries industryClass={industryCssClass} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} playlist={playlist}/>
                )
            case "special-series":
                //TODO: Remove this after algolia syncs
                return(
                    <SpecialSeries industryClass={industryCssClass} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} playlist={playlist}/>
                )
            case CollectionThumbnailStyle.Audio:
                return(
                    <AudioStyle durationMinutes={null} durationSeconds={null} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} progress={null} content={playlist} />
                )
            default:
                return(
                    <GenericTile title={playlist.title} path={playlist.path} />
                )
        }
    }

    return(
        <div className="tile-component">
            <Link
                to={`/playlist/${playlist.id}`}
                className={`tile-container ${props.className ?? ''}`}>
                {renderTile(playlist.thumbnailStyle)}
            </Link>
            <div className={"title-and-description-container"}>
                {(props.showTitle ?? true) &&<div className={"out-of-tile-title caption"}>{playlist.title}</div>}
                {(props.showDescription ?? false) && <div className={"caption"}>{playlist.description}</div>}
            </div>
        </div>

    )
}
